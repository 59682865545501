@use 'styles/color';

.icon-button {
  position: relative;
  width: 24px;
}
.hover {
  &:hover {
    opacity: 0.6;
  }
}
.num-notification {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -4px;
  right: -5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: none;
  background-color: #ef5d60;
  font-weight: 400;
  color: color.$white;
  line-height: 14px;
}
.normal-font-size {
  font-size: 10px;
}
.small-font-size {
  font-size: 10px;
  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  -ms-transform: scale(0.75);
  -o-transform: scale(0.75);
  transform: scale(0.75);
}
