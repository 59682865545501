@use 'styles/color';

.page-url {
  &:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}
.notification {
  display: flex;
  flex-direction: row;
  position: relative;
}
.badge {
  position: absolute;
  left: -16px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #549186;
}
.image-container {
  width: 72px;
  height: 72px;
  padding: 7px;
  border: 1px solid color.$gray20;
  border-radius: 4px;
  background-color: color.$gray10;
}
.info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.info-header {
  display: flex;
  justify-content: space-between;
}
.date {
  display: flex;
  align-items: center;
  flex-direction: row;

  &.right-edge {
    padding-right: 0px;
  }
  &.left {
    padding-right: 729px;
  }
  &.right {
    padding-right: 48px;
  }
}
.date-from-now {
  color: color.$gray50;
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
}
.content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 1.4;
  color: color.$gray90;
}

.label {
  background-color: var(--bg-color);
  color: color.$white;
  padding: 4px 8px;
  border-radius: 40px;
  text-align: center;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
}
