@use 'styles/color';

.container {
  position: absolute;
  top: 49px;
  left: -200px;
  display: flex;
  flex-direction: column;
  width: 375px;
  padding: 28px;
  background-color: color.$white;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  z-index: 10;
}

.title {
  color: color.$gray90;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
}
.check-notifications {
  border: 1px solid color.$soft-orange;
  border-radius: 8px;
  padding: 13px;
  text-align: center;
  color: color.$soft-orange;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 400;
}
.link {
  &:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

.empty-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-container {
  width: 108px;
  height: 108px;
  background-color: color.$gray10;
  border: 3px solid color.$gray10;
  border-radius: 50%;
}
.no-notification {
  font-size: 16px;
  line-height: 1;
  color: color.$gray90;
  text-align: center;
  font-weight: 700;
}
.description {
  font-size: 14px;
  line-height: 1.4;
  color: color.$gray50;
  text-align: center;
  font-weight: 400;
}
