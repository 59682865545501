.spacer {
  display: block;
}

@for $i from 1 through 32 {
  .size-#{$i} {
    width: #{$i}px;
    min-width: #{$i}px;
    height: #{$i}px;
    min-height: #{$i}px;
  }
}
