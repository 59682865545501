$white: #ffffff;
$gray10: #fafafa;
$gray20: #e0e0e0;
$gray30: #bdbdbd;
$gray50: #757575;
$gray80: #353535;
$gray90: #212121;
$black: #000000;
$soft-orange: #e4a357;
$soft-red: #ef5d60;
$red: #ff0000;
$dark-red: #a40d0d;
$blue: #2b55c2;
$linen: #faeedf;
